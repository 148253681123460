/* eslint-disable */
import { DocumentTypeDecoration } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** An ISO 8601-encoded date */
  ISO8601Date: { input: any; output: any; }
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: { input: any; output: any; }
};

/** Autogenerated input type of CompleteOnboarding */
export type CompleteOnboardingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CompleteOnboarding. */
export type CompleteOnboardingPayload = {
  __typename?: 'CompleteOnboardingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  userOnboarding: UserOnboarding;
};

export type CrowdedMatrix = {
  __typename?: 'CrowdedMatrix';
  endTime: Scalars['String']['output'];
  endingMinute: Scalars['Int']['output'];
  key: Scalars['String']['output'];
  startTime: Scalars['String']['output'];
  startingMinute: Scalars['Int']['output'];
  text: Scalars['String']['output'];
  weekdayType: Scalars['String']['output'];
};

/** Autogenerated input type of EnqueteCreate */
export type EnqueteCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** User Attribute Input */
  inputs: EnqueteInput;
};

/** Autogenerated return type of EnqueteCreate. */
export type EnqueteCreatePayload = {
  __typename?: 'EnqueteCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  userAttribute: UserAttribute;
};

/** User Attribute Input */
export type EnqueteInput = {
  /** 年齢層 */
  ageGroup: Scalars['String']['input'];
  /** 誰とカフェに行く予定か? */
  go2cafeWith?: InputMaybe<Scalars['String']['input']>;
  /** ニックネーム */
  nickname: Scalars['String']['input'];
  /** 性別 */
  sex: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  completeOnboarding?: Maybe<CompleteOnboardingPayload>;
  enqueteCreate?: Maybe<EnqueteCreatePayload>;
  /** Creates a user push idToken for firebase messaging */
  pushIdtokenCreate?: Maybe<PushIdtokenCreatePayload>;
  /** Destroy a user push idToken for firebase messaging */
  pushIdtokenDestroy?: Maybe<PushIdtokenDestroyPayload>;
  /** Creates a new spot_info_revision_suggestion */
  spotInfoRevisionSuggestionCreate?: Maybe<SpotInfoRevisionSuggestionCreatePayload>;
  /** Creates a new spot_review */
  spotReviewCreate?: Maybe<SpotReviewCreatePayload>;
};


export type MutationCompleteOnboardingArgs = {
  input: CompleteOnboardingInput;
};


export type MutationEnqueteCreateArgs = {
  input: EnqueteCreateInput;
};


export type MutationPushIdtokenCreateArgs = {
  input: PushIdtokenCreateInput;
};


export type MutationPushIdtokenDestroyArgs = {
  input: PushIdtokenDestroyInput;
};


export type MutationSpotInfoRevisionSuggestionCreateArgs = {
  input: SpotInfoRevisionSuggestionCreateInput;
};


export type MutationSpotReviewCreateArgs = {
  input: SpotReviewCreateInput;
};

export type PointInput = {
  lat: Scalars['Float']['input'];
  lng: Scalars['Float']['input'];
};

/** Autogenerated input type of PushIdtokenCreate */
export type PushIdtokenCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  input: PushIdtokenInput;
};

/** Autogenerated return type of PushIdtokenCreate. */
export type PushIdtokenCreatePayload = {
  __typename?: 'PushIdtokenCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  user: User;
};

/** Autogenerated input type of PushIdtokenDestroy */
export type PushIdtokenDestroyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of PushIdtokenDestroy. */
export type PushIdtokenDestroyPayload = {
  __typename?: 'PushIdtokenDestroyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  user: User;
};

/** Push ID Token Create Input */
export type PushIdtokenInput = {
  token: Scalars['String']['input'];
};

export type Query = {
  __typename?: 'Query';
  /** logged in user info */
  me?: Maybe<User>;
  spot: Spot;
  spots: Array<Spot>;
};


export type QuerySpotArgs = {
  code: Scalars['String']['input'];
  currentTime?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
};


export type QuerySpotsArgs = {
  bbox?: InputMaybe<Array<Scalars['Float']['input']>>;
  center?: InputMaybe<PointInput>;
  currentTime?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  radius?: InputMaybe<Scalars['Float']['input']>;
};

/** Spot list */
export type Spot = {
  __typename?: 'Spot';
  active: Scalars['Boolean']['output'];
  addressfull?: Maybe<Scalars['String']['output']>;
  allSpotPhotos: Array<SpotPhoto>;
  budgetDinner?: Maybe<SpotCharge>;
  budgetLunch?: Maybe<SpotCharge>;
  businessHours: Array<SpotBusinessHour>;
  businessHoursNote?: Maybe<Scalars['String']['output']>;
  code: Scalars['String']['output'];
  crowdedLevel: Scalars['String']['output'];
  firstSpotPhoto?: Maybe<SpotPhoto>;
  homepageUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imakoAdvice: Scalars['String']['output'];
  isOpen: Scalars['Boolean']['output'];
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  seatCount?: Maybe<Scalars['Int']['output']>;
  seatNote?: Maybe<Scalars['String']['output']>;
  seatsText: Scalars['String']['output'];
  smoking: Scalars['String']['output'];
  smokingNote?: Maybe<Scalars['String']['output']>;
  smokingType: Scalars['String']['output'];
  spotCrowdInformationStatus?: Maybe<SpotCrowdInformationStatus>;
  spotCrowdInformations: Array<SpotCrowdInformation>;
  spotPhotos: Array<SpotPhoto>;
  spotPhotosSize: Scalars['Int']['output'];
  tabelogUrl?: Maybe<Scalars['String']['output']>;
  todayBusinessHour: Array<SpotBusinessHour>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type SpotBusinessHour = {
  __typename?: 'SpotBusinessHour';
  endTime?: Maybe<Scalars['String']['output']>;
  endTimeMinutes?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  index: Scalars['Int']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  startTime?: Maybe<Scalars['String']['output']>;
  startTimeMinutes?: Maybe<Scalars['Int']['output']>;
  weekday: Scalars['String']['output'];
};

export type SpotCharge = {
  __typename?: 'SpotCharge';
  id: Scalars['ID']['output'];
  kind: Scalars['String']['output'];
  max?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
};

/** 混雑情報 */
export type SpotCrowdInformation = {
  __typename?: 'SpotCrowdInformation';
  crowdLevel: Scalars['String']['output'];
  crowdedMatrix: CrowdedMatrix;
  /** ID */
  id: Scalars['ID']['output'];
};

export type SpotCrowdInformationStatus = {
  __typename?: 'SpotCrowdInformationStatus';
  checked: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  lastCheckedOn: Scalars['ISO8601Date']['output'];
};

export type SpotInfoRevisionSuggestion = {
  __typename?: 'SpotInfoRevisionSuggestion';
  content?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  kind: Scalars['String']['output'];
  name: Scalars['String']['output'];
  spot: Spot;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  user?: Maybe<User>;
};

/** Autogenerated input type of SpotInfoRevisionSuggestionCreate */
export type SpotInfoRevisionSuggestionCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  spotInfoRevisionSuggestionInput: SpotInfoRevisionSuggestionInput;
};

/** Autogenerated return type of SpotInfoRevisionSuggestionCreate. */
export type SpotInfoRevisionSuggestionCreatePayload = {
  __typename?: 'SpotInfoRevisionSuggestionCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  spotInfoRevisionSuggestion: SpotInfoRevisionSuggestion;
};

export type SpotInfoRevisionSuggestionInput = {
  content: Scalars['String']['input'];
  kind: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  spotCode: Scalars['String']['input'];
};

export type SpotPhoto = {
  __typename?: 'SpotPhoto';
  /** コード */
  code?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** ダウンロード時のURL */
  downloadedUrl?: Maybe<Scalars['String']['output']>;
  /** ID */
  id: Scalars['ID']['output'];
  /** 格納先のパス */
  imagePath: Scalars['String']['output'];
  /** 配信時のURL */
  imageUrl: Scalars['String']['output'];
  /** 画像かどうか */
  isImage: Scalars['Boolean']['output'];
  /** MIMEタイプ */
  mimeType?: Maybe<Scalars['String']['output']>;
  /** 画像の並べ替え */
  orderId: Scalars['Int']['output'];
  /** オリジナルのファイル名 */
  originalFileName?: Maybe<Scalars['String']['output']>;
  /** 参照種別 */
  originalFrom: Scalars['String']['output'];
  /** 配信時のURL(オリジナルのサイズ) */
  originalSizeUrl: Scalars['String']['output'];
  /** 参照元のURL */
  originalUrl?: Maybe<Scalars['String']['output']>;
  /** サムネイル画像のURL */
  thumbnailImageUrl?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  /** 公開中かどうか */
  visible: Scalars['Boolean']['output'];
};

export type SpotReview = {
  __typename?: 'SpotReview';
  comment?: Maybe<Scalars['String']['output']>;
  crowdedLevel: Scalars['String']['output'];
  name: Scalars['String']['output'];
  spot: Spot;
  user?: Maybe<User>;
  visitedOn: Scalars['ISO8601Date']['output'];
  visitedTime: Scalars['Int']['output'];
};

/** Autogenerated input type of SpotReviewCreate */
export type SpotReviewCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  spotReviewInput: SpotReviewInput;
};

/** Autogenerated return type of SpotReviewCreate. */
export type SpotReviewCreatePayload = {
  __typename?: 'SpotReviewCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  spotReview: SpotReview;
};

export type SpotReviewInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  crowdedLevel: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  reviewType: Scalars['String']['input'];
  spotCode: Scalars['String']['input'];
  visitedOn: Scalars['ISO8601DateTime']['input'];
  visitedTime: Scalars['Int']['input'];
};

export type User = {
  __typename?: 'User';
  code: Scalars['String']['output'];
  completeOnboarding: Scalars['Boolean']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  uid: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  userAttribute?: Maybe<UserAttribute>;
  userOnboarding?: Maybe<UserOnboarding>;
};

export type UserAttribute = {
  __typename?: 'UserAttribute';
  ageGroup?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  go2cafeWith?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  nickname?: Maybe<Scalars['String']['output']>;
  sex?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  user?: Maybe<User>;
};

export type UserOnboarding = {
  __typename?: 'UserOnboarding';
  completedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['ID']['output'];
};

export type CompleteOnboardingMutationVariables = Exact<{
  completeOnboardingInput: CompleteOnboardingInput;
}>;


export type CompleteOnboardingMutation = { __typename?: 'Mutation', completeOnboarding?: { __typename?: 'CompleteOnboardingPayload', userOnboarding: { __typename?: 'UserOnboarding', id: string, completedAt?: any | null } } | null };

export type EnqueteCreateMutationVariables = Exact<{
  input: EnqueteCreateInput;
}>;


export type EnqueteCreateMutation = { __typename?: 'Mutation', enqueteCreate?: { __typename?: 'EnqueteCreatePayload', userAttribute: { __typename?: 'UserAttribute', sex?: string | null, ageGroup?: string | null, nickname?: string | null } } | null };

export type PushIdtokenCreateMutationVariables = Exact<{
  input: PushIdtokenCreateInput;
}>;


export type PushIdtokenCreateMutation = { __typename?: 'Mutation', pushIdtokenCreate?: { __typename?: 'PushIdtokenCreatePayload', user: { __typename?: 'User', code: string } } | null };

export type PushIdtokenDestroyMutationVariables = Exact<{
  input: PushIdtokenDestroyInput;
}>;


export type PushIdtokenDestroyMutation = { __typename?: 'Mutation', pushIdtokenDestroy?: { __typename?: 'PushIdtokenDestroyPayload', user: { __typename?: 'User', code: string } } | null };

export type SpotInfoRevisionSuggestionCreateMutationVariables = Exact<{
  input: SpotInfoRevisionSuggestionCreateInput;
}>;


export type SpotInfoRevisionSuggestionCreateMutation = { __typename?: 'Mutation', spotInfoRevisionSuggestionCreate?: { __typename?: 'SpotInfoRevisionSuggestionCreatePayload', spotInfoRevisionSuggestion: { __typename?: 'SpotInfoRevisionSuggestion', content?: string | null, createdAt: any, id: string, kind: string, updatedAt: any, spot: { __typename?: 'Spot', name: string } } } | null };

export type SpotReviewLevelCreateMutationVariables = Exact<{
  input: SpotReviewCreateInput;
}>;


export type SpotReviewLevelCreateMutation = { __typename?: 'Mutation', spotReviewCreate?: { __typename?: 'SpotReviewCreatePayload', spotReview: { __typename?: 'SpotReview', comment?: string | null, crowdedLevel: string, name: string, visitedOn: any, visitedTime: number } } | null };

export type AllSpotsQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type AllSpotsQueryQuery = { __typename?: 'Query', spots: Array<{ __typename?: 'Spot', code: string, updatedAt: any }> };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'User', code: string, completeOnboarding: boolean, userAttribute?: { __typename?: 'UserAttribute', id: string } | null } | null };

export type SpotQueryQueryVariables = Exact<{
  code: Scalars['String']['input'];
}>;


export type SpotQueryQuery = { __typename?: 'Query', spot: (
    { __typename?: 'Spot' }
    & { ' $fragmentRefs'?: { 'SpotItemFragment': SpotItemFragment } }
  ) };

export type SpotItemFragment = { __typename?: 'Spot', code: string, latitude?: number | null, longitude?: number | null, name: string, phoneNumber?: string | null, homepageUrl?: string | null, isOpen: boolean, imakoAdvice: string, crowdedLevel: string, addressfull?: string | null, businessHoursNote?: string | null, smoking: string, seatsText: string, budgetDinner?: { __typename?: 'SpotCharge', max?: number | null, min?: number | null } | null, budgetLunch?: { __typename?: 'SpotCharge', max?: number | null, min?: number | null } | null, todayBusinessHour: Array<{ __typename?: 'SpotBusinessHour', endTime?: string | null, startTime?: string | null }>, spotCrowdInformationStatus?: { __typename?: 'SpotCrowdInformationStatus', lastCheckedOn: any } | null, spotCrowdInformations: Array<{ __typename?: 'SpotCrowdInformation', crowdLevel: string, crowdedMatrix: { __typename?: 'CrowdedMatrix', endTime: string, startTime: string, key: string } }>, spotPhotos: Array<{ __typename?: 'SpotPhoto', code?: string | null, imageUrl: string, originalFrom: string, originalUrl?: string | null, isImage: boolean }> } & { ' $fragmentName'?: 'SpotItemFragment' };

export type SpotsQueryQueryVariables = Exact<{
  bbox?: InputMaybe<Array<Scalars['Float']['input']> | Scalars['Float']['input']>;
}>;


export type SpotsQueryQuery = { __typename?: 'Query', spots: Array<(
    { __typename?: 'Spot' }
    & { ' $fragmentRefs'?: { 'SpotsItemFragment': SpotsItemFragment } }
  )> };

export type SpotsItemFragment = { __typename?: 'Spot', code: string, latitude?: number | null, longitude?: number | null, name: string, isOpen: boolean, crowdedLevel: string, firstSpotPhoto?: { __typename?: 'SpotPhoto', imageUrl: string, thumbnailImageUrl?: string | null } | null } & { ' $fragmentName'?: 'SpotsItemFragment' };

export class TypedDocumentString<TResult, TVariables>
  extends String
  implements DocumentTypeDecoration<TResult, TVariables>
{
  __apiType?: DocumentTypeDecoration<TResult, TVariables>['__apiType'];

  constructor(private value: string, public __meta__?: Record<string, any> | undefined) {
    super(value);
  }

  toString(): string & DocumentTypeDecoration<TResult, TVariables> {
    return this.value;
  }
}
export const SpotItemFragmentDoc = new TypedDocumentString(`
    fragment SpotItem on Spot {
  budgetDinner {
    max
    min
  }
  budgetLunch {
    max
    min
  }
  code
  latitude
  longitude
  name
  phoneNumber
  homepageUrl
  isOpen
  imakoAdvice
  crowdedLevel
  addressfull
  businessHoursNote
  smoking
  seatsText
  todayBusinessHour {
    endTime
    startTime
  }
  spotCrowdInformationStatus {
    lastCheckedOn
  }
  spotCrowdInformations {
    crowdedMatrix {
      endTime
      startTime
      key
    }
    crowdLevel
  }
  spotPhotos {
    code
    imageUrl
    originalFrom
    originalUrl
    isImage
  }
}
    `, {"fragmentName":"SpotItem"}) as unknown as TypedDocumentString<SpotItemFragment, unknown>;
export const SpotsItemFragmentDoc = new TypedDocumentString(`
    fragment SpotsItem on Spot {
  code
  latitude
  longitude
  name
  isOpen
  crowdedLevel
  firstSpotPhoto {
    imageUrl
    thumbnailImageUrl
  }
}
    `, {"fragmentName":"SpotsItem"}) as unknown as TypedDocumentString<SpotsItemFragment, unknown>;
export const CompleteOnboardingDocument = new TypedDocumentString(`
    mutation completeOnboarding($completeOnboardingInput: CompleteOnboardingInput!) {
  completeOnboarding(input: $completeOnboardingInput) {
    userOnboarding {
      id
      completedAt
    }
  }
}
    `) as unknown as TypedDocumentString<CompleteOnboardingMutation, CompleteOnboardingMutationVariables>;
export const EnqueteCreateDocument = new TypedDocumentString(`
    mutation enqueteCreate($input: EnqueteCreateInput!) {
  enqueteCreate(input: $input) {
    userAttribute {
      sex
      ageGroup
      nickname
    }
  }
}
    `) as unknown as TypedDocumentString<EnqueteCreateMutation, EnqueteCreateMutationVariables>;
export const PushIdtokenCreateDocument = new TypedDocumentString(`
    mutation pushIdtokenCreate($input: PushIdtokenCreateInput!) {
  pushIdtokenCreate(input: $input) {
    user {
      code
    }
  }
}
    `) as unknown as TypedDocumentString<PushIdtokenCreateMutation, PushIdtokenCreateMutationVariables>;
export const PushIdtokenDestroyDocument = new TypedDocumentString(`
    mutation pushIdtokenDestroy($input: PushIdtokenDestroyInput!) {
  pushIdtokenDestroy(input: $input) {
    user {
      code
    }
  }
}
    `) as unknown as TypedDocumentString<PushIdtokenDestroyMutation, PushIdtokenDestroyMutationVariables>;
export const SpotInfoRevisionSuggestionCreateDocument = new TypedDocumentString(`
    mutation spotInfoRevisionSuggestionCreate($input: SpotInfoRevisionSuggestionCreateInput!) {
  spotInfoRevisionSuggestionCreate(input: $input) {
    spotInfoRevisionSuggestion {
      content
      createdAt
      id
      kind
      spot {
        name
      }
      updatedAt
    }
  }
}
    `) as unknown as TypedDocumentString<SpotInfoRevisionSuggestionCreateMutation, SpotInfoRevisionSuggestionCreateMutationVariables>;
export const SpotReviewLevelCreateDocument = new TypedDocumentString(`
    mutation spotReviewLevelCreate($input: SpotReviewCreateInput!) {
  spotReviewCreate(input: $input) {
    spotReview {
      comment
      crowdedLevel
      name
      visitedOn
      visitedTime
    }
  }
}
    `) as unknown as TypedDocumentString<SpotReviewLevelCreateMutation, SpotReviewLevelCreateMutationVariables>;
export const AllSpotsQueryDocument = new TypedDocumentString(`
    query allSpotsQuery {
  spots {
    code
    updatedAt
  }
}
    `) as unknown as TypedDocumentString<AllSpotsQueryQuery, AllSpotsQueryQueryVariables>;
export const MeDocument = new TypedDocumentString(`
    query Me {
  me {
    code
    userAttribute {
      id
    }
    completeOnboarding
  }
}
    `) as unknown as TypedDocumentString<MeQuery, MeQueryVariables>;
export const SpotQueryDocument = new TypedDocumentString(`
    query spotQuery($code: String!) {
  spot(code: $code) {
    ...SpotItem
  }
}
    fragment SpotItem on Spot {
  budgetDinner {
    max
    min
  }
  budgetLunch {
    max
    min
  }
  code
  latitude
  longitude
  name
  phoneNumber
  homepageUrl
  isOpen
  imakoAdvice
  crowdedLevel
  addressfull
  businessHoursNote
  smoking
  seatsText
  todayBusinessHour {
    endTime
    startTime
  }
  spotCrowdInformationStatus {
    lastCheckedOn
  }
  spotCrowdInformations {
    crowdedMatrix {
      endTime
      startTime
      key
    }
    crowdLevel
  }
  spotPhotos {
    code
    imageUrl
    originalFrom
    originalUrl
    isImage
  }
}`) as unknown as TypedDocumentString<SpotQueryQuery, SpotQueryQueryVariables>;
export const SpotsQueryDocument = new TypedDocumentString(`
    query spotsQuery($bbox: [Float!]) {
  spots(bbox: $bbox) {
    ...SpotsItem
  }
}
    fragment SpotsItem on Spot {
  code
  latitude
  longitude
  name
  isOpen
  crowdedLevel
  firstSpotPhoto {
    imageUrl
    thumbnailImageUrl
  }
}`) as unknown as TypedDocumentString<SpotsQueryQuery, SpotsQueryQueryVariables>;